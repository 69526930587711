import React, { useEffect, useState } from "react";
import {
  ListboxInput,
  ListboxButton,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from "@reach/listbox";

import { getThemeProps } from "style";

import Icon from "components/Icon";
import Box from "components/Box";

const defaultProps = {
  variant: "default",
};

const style = {
  popover: {
    position: "absolute",
    display: "block",
    minWidth: "min-content",
    outline: "none",
    "&[hidden]": {
      display: "none",
    },
  },
  input: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: "xs",
    py: 2,
    px: 3,
    border: "1px solid",
    borderColor: "gray.200",
    borderRadius: "sm",
    color: "inherit",
    bg: "white",
    cursor: "default",
    userSelect: "none",
    ":hover:not([aria-expanded='true'])": {
      bg: "gray.100",
    },
    "[data-invalid] &": {
      borderColor: "brick.900",
    },
  },
  list: {
    minWidth: "xs",
    m: 0,
    mt: 1,
    py: 2,
    px: 0,
    bg: "white",
    border: "1px solid",
    borderColor: "gray.200",
    borderRadius: "sm",
    boxShadow: "md",
    fontSize: 1,
    listStyle: "none",
    overflow: "hidden",
    ":focus": {
      outline: "none",
    },
    "[data-invalid] &": {
      borderColor: "brick.900",
    },
  },
  option: {
    display: "block",
    m: "0",
    py: 2,
    px: 3,
    variant: "truncate",
    "&[aria-selected]": {
      color: "background",
      backgroundColor: "focus",
    },
    "&[data-current]": {
      fontWeight: "bolder",
    },
  },
  icon: {
    ml: 3,
  },
};

const Listbox = React.forwardRef(
  (
    {
      onChange,
      onInvalid,
      options,
      name,
      required,
      value: controlledValue,
      variant,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(name);

    const handleChange = React.useCallback(
      value => {
        setValue(value);
        onChange(value);
      },
      [onChange, setValue]
    );

    const themeProps = getThemeProps({
      style,
      themeKey: "forms.listbox",
      variant,
    });

    useEffect(() => {
      if (
        controlledValue !== null &&
        typeof controlledValue !== "undefined" &&
        controlledValue !== value
      ) {
        setValue(controlledValue);
      }
    }, [controlledValue, setValue, value]);

    function renderOption(option) {
      return (
        <Box
          as={ListboxOption}
          children={option}
          key={option}
          value={option}
          {...themeProps("option")}
        />
      );
    }

    return (
      <ListboxInput value={value} onChange={handleChange}>
        <Box as={ListboxButton} {...themeProps("input", props)}>
          {value}
          <Icon icon="chevron-down" {...themeProps("icon")} />
        </Box>
        <Box as={ListboxPopover} {...themeProps("popover")}>
          <Box as={ListboxList} {...themeProps("list")}>
            {options.map(option => renderOption(option))}
          </Box>
        </Box>
        <input
          name={name}
          onInvalid={onInvalid}
          ref={ref}
          required={required}
          type="hidden"
          value={value}
        />
      </ListboxInput>
    );
  }
);

Listbox.defaultProps = defaultProps;

export default Listbox;
